import React, { useState } from "react";
import "./Login.scss"
import { Col, Row, Button, FormGroup, Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { storeUser } from "../../helper";

const initialUser = { password: "", identifier: "" };

const Login = () => {
    const [user, setUser] = useState(initialUser);
    const navigate = useNavigate();
  
    const handleChange = ({ target }) => {
      const { name, value } = target;
      setUser((currentUser) => ({
        ...currentUser,
        [name]: value,
      }));
    };
  
    const handleLogin = async () => {
      const url = `https://ntse.onrender.com/api/auth/local`;
      try {
        if (user.identifier && user.password) {
          const { data } = await axios.post(url, user);
          if (data.jwt) {
            storeUser(data);
            toast.success("Logged in successfully!", {
              hideProgressBar: true,
            });
            setUser(initialUser);
            navigate("/");
          }
        }
      } catch (error) {
        toast.error(error.message, {
          hideProgressBar: true,
        });
      }
    };
  return (
    <section className="body1">
    <section className="container">
      <header style={{ marginBottom: "20px" }}>Registration Form</header>
      <span style={{ color: "red" }}>
        Please Fill all details correctly if you make any mistake we are not
        responsible
      </span>
      <form  className="form">
     
        <div className="input-box">
          <label>Email Address</label>
          <input
            
            placeholder="Enter email address"
            type="email"
            name="identifier"
            value={user.identifier}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-box">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={user.password}
            onChange={handleChange}
            placeholder="Enter password address"
           
            required
          />
        </div>
       
       
      

       
        <Button  style={{ marginBottom: "20px" }}
          className="add-to-cart-button" onClick={handleLogin}>
            Login
          </Button>
        <Button  style={{ marginBottom: "20px" }}
          className="add-to-cart-button" onClick={handleLogin}>
            Click <Link to="/signup">Here</Link> to sign up
          </Button>
         
      
      </form>
     
    </section>
  </section>
  )
}

export default Login
