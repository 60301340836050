import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row, Button, FormGroup, Input } from "reactstrap";
import "./Signup.scss";
const initialUser = { email: "", password: "", username: "" };
const Signup = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const signUp = async () => {
    try {
      const url = `https://ntse.onrender.com/api/auth/local/register`;
      if (user.username && user.email && user.password) {
        const res = await axios.post(url, user);
        if (!!res) {
          toast.success("Registered successfully!", {
            hideProgressBar: true,
          });
          setUser(initialUser);
          navigate("/login");
        }
      }
    } catch (error) {
      toast.error(error.message, {
        hideProgressBar: true,
      });
    }
  };

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  return (
    <>
      <section className="body1">
        <section className="container">
          <header style={{ marginBottom: "20px" }}>Registration Form</header>
          <span style={{ color: "red" }}>
            Please Fill all details correctly if you make any mistake we are not
            responsible
          </span>
          <form className="form">
            <div className="input-box">
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={user.username}
                onChange={handleUserChange}
                placeholder="Enter your full name"
                required
              />
            </div>
            <div className="input-box">
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                value={user.email}
                onChange={handleUserChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="input-box">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={user.password}
                onChange={handleUserChange}
                placeholder="Enter password"
                required
              />
            </div>

            <Button color="primary" onClick={signUp}>
              Sign up
            </Button>
          </form>
        </section>
      </section>
    </>
  );
};

export default Signup;
