import React, { useState, useEffect } from "react";
import "./Account.scss"
import "./Account.scss";
import { userData, userEmail } from "../../helper";
import useFetch from "../../hooks/useFetch";
import { fetchDataFromApi } from "../../utils/api";
const Account = () => {
  const [reg, setReg] = useState();
  const { username } = userData();
  const { email } = userEmail();

  // const lens = data?.meta?.pagination?.total
  useEffect(() => {
    getReg();
  }, []);
  const getReg = () => {
    // const { data } = useFetch(`/api/registrations?populate=*&[filters][email]=${email}`);
    // console.log(data);
    // setReg(data);
    fetchDataFromApi(
      `/api/registrations?populate=*&[filters][email]=${email}`
    ).then((res) => {
     
      setReg(res);
    });
  };
  return (
    <>
    <div style={{ textAlign: "center", marginTop: "40px", minHeight: "100vh" }}>
      <h1>Welcome {username}</h1>
      <h2 style={{margin:"20px"}}>Your Email is {email}</h2>
      <div className="search-result-content">

     
      </div>
      {reg?.data?.map((item) => (
          <>
        
      <div class="column">
    <div class="card">
      <h3>{item?.attributes?.name}</h3>
      <p>{item?.attributes?.olympiad}</p>
      <p>Price ₹250</p>
      <p>{item?.attributes?.publishedAt}</p>
    </div>
  </div>
        
        </>
      ))}
    
    </div>
        

      </>
    
  );
};

export default Account;
