import React, { Component } from 'react';
import { Carousel } from 'react-carousel-minimal';

import "./About"

const About = () => {
    const data = [
      {
        image: "https://res.cloudinary.com/daxabtpvu/image/upload/v1698263861/2_0424b05335.png",
        caption: "First prize upto ₹30000"
      },
        {

          image: "https://res.cloudinary.com/daxabtpvu/image/upload/v1698263829/1_d2733f408d.png",
          
          caption: "Second prize upto ₹20000"
        },
        {
          image: "https://res.cloudinary.com/daxabtpvu/image/upload/v1698263941/3_910eb6914b.png",
          caption: "Third prize upto ₹10000"
        }
       
      ];
      const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
        // color:"black"
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }
   
  return (
<div className="App">
      <div style={{ textAlign: "center", marginTop:"40px"}}>
        <h2>NTSE PRIZE</h2>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="950px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
           
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "600px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default About;
